import { Flex, Text } from '@chakra-ui/layout';
import { Stack, useColorModeValue } from '@chakra-ui/react';
import moment from 'moment-timezone';
import React from 'react';

function InviteItem({ invited, status, invitationDate }) {
  const boxBg = useColorModeValue('white', 'rgb(27 34 46)');

  return (
    <Stack
      display="flex"
      flexDir="column"
      background={boxBg}
      borderRadius={8}
      p={4}
    >
      <Flex flexDir="row" alignItems="center">
        <Text fontWeight="semibold">Invited:</Text>
        <Text ml={4}>{invited}</Text>
      </Flex>
      <Flex flexDir="row" alignItems="center">
        <Text fontWeight="semibold">Status:</Text>
        <Text ml={4}>{status}</Text>
      </Flex>
      <Text fontSize="xs" alignSelf="flex-end">
        {moment(invitationDate).format('YYYY-MM-DD H:mm a')}
      </Text>
    </Stack>
  );
}

export default InviteItem;
