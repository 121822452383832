import React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import PageWrapper from '../../components/PageWrapper';
import {
  useFormInput,
  useLoading,
  useViewController,
} from '../../utils/hooks.js';
import { login } from '../../services/AuthService.js';
import { useNotifyContext } from '../../context/NotifyContext.js';
import ForgotPassword from '../../components/ForgotPassword';

function Login() {
  const [show, setShow] = useState(false);
  const notify = useNotifyContext();

  const phoneNumber = useFormInput('');
  const password = useFormInput('');

  const loader = useLoading();

  const viewController = useViewController(
    ['LOGIN', 'FORGOT_PASSWORD'],
    'LOGIN',
  );

  async function onLoginClick() {
    loader.start();
    try {
      await login({
        phoneNumber: phoneNumber.value,
        password: password.value,
      });

      notify.success('Logged In!');
      window.location.reload();
      loader.stop();
    } catch (e) {
      notify.error(e.response.data && e.response.data.error);
      loader.stop();
    }
  }

  function onKeyPress(e) {
    if (e.key !== 'Enter') return;
    if (isInputDiabled()) return;

    onLoginClick();
  }

  function isInputDiabled() {
    if (loader.isLoading() || !phoneNumber.value || !password.value)
      return true;
    return false;
  }

  return (
    <PageWrapper alignItems="center" justifyContent="center">
      <Stack minW="300px" spacing={4} display="flex" flexDir="column">
        {viewController.current === viewController.VIEWS.FORGOT_PASSWORD && (
          <ForgotPassword
            goBackToLogin={() => viewController.set(viewController.VIEWS.LOGIN)}
          />
        )}
        {viewController.current === viewController.VIEWS.LOGIN && (
          <React.Fragment>
            <Text textAlign="center" fontSize="3xl" fontWeight="semibold">
              login to your account
            </Text>
            <FormControl onKeyPress={onKeyPress} isRequired id="phoneNumber">
              <InputGroup>
                <InputLeftAddon children="+1" />

                <Input
                  disabled={loader.isLoading()}
                  {...phoneNumber}
                  placeholder="phone number"
                  type="tel"
                />
              </InputGroup>
            </FormControl>
            <FormControl onKeyPress={onKeyPress} isRequired id="password">
              <InputGroup>
                <Input
                  {...password}
                  disabled={loader.isLoading()}
                  placeholder="password"
                  type={show ? 'text' : 'password'}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShow(state => !state)}
                  >
                    {show ? 'hide' : 'show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Text
              cursor="pointer"
              textAlign="end"
              color="blue.600"
              fontSize="x-small"
              onClick={() =>
                viewController.set(viewController.VIEWS.FORGOT_PASSWORD)
              }
              textDecor="underline"
            >
              forgot password?
            </Text>
            <Button
              isLoading={loader.isLoading()}
              onClick={onLoginClick}
              isDisabled={isInputDiabled()}
              size="sm"
              colorScheme="blue"
            >
              log in
            </Button>
          </React.Fragment>
        )}
      </Stack>
    </PageWrapper>
  );
}

export default Login;
