import moment from 'moment-timezone';

const MILLI_IN_DAY = 1000 * 60 * 60 * 24;

export function isPlanExpired(planExpirationDate) {
  if (moment(planExpirationDate).isBefore(moment())) return true;
  return false;
}

export function getNextBillingDate(planExpirationDate, days) {
  if (isPlanExpired(planExpirationDate)) {
    return moment()
      .add(days, 'days')
      .format('MM/DD/YYY');
  }
  const date = moment(planExpirationDate).add(days, 'days');
  return date.format('MM/DD/YYYY');
}

export function calculateDaysLeftOfPlan(planExpirationDate) {
  const today = Date.now();
  const lastDate = Number(moment(planExpirationDate).format('x'));
  const diff = today - lastDate;
  const days = Math.floor(diff / MILLI_IN_DAY);

  return days;
}
