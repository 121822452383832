import React, { createContext, useContext, useState } from 'react';
import { Modal, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react';

const LoadingContext = createContext();

export function useLoadingContext() {
  return useContext(LoadingContext);
}

export function LoadingContextProvider({ children }) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        isLoading: () => loading,
        start: () => setLoading(true),
        stop: () => setLoading(false),
      }}
    >
      <Modal isOpen={loading} isCentered>
        <ModalOverlay />
        <ModalContent
          background="rgba(0,0,0,0.0)"
          boxShadow="none"
          height="200px"
          opacity="0.7"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size="xl" colorScheme="blue" />
        </ModalContent>
      </Modal>
      {children}
    </LoadingContext.Provider>
  );
}

export default LoadingContextProvider;
