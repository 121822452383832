import { createContext, useContext, useState } from 'react';

const TabContext = createContext();

export function useTabContext() {
  return useContext(TabContext);
}

export function TabContextProvider({ initialTab, children }) {
  const [currentTab, setCurrentTab] = useState(initialTab);
  return (
    <TabContext.Provider value={{ currentTab, setCurrentTab }}>
      {children}
    </TabContext.Provider>
  );
}
