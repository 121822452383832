import HttpService from './HttpService';

const apiUrl = `/flex-setup`;
const apiUrlV2 = `/flex-setup/v2`;
const apiUrlV3 = `/flex-setup/v3`;

export async function getFlexStatus(userId) {
  try {
    const resp = await HttpService.get(`/flex-setup/status?userId=${userId}`);
    return resp.data;
  } catch (e) {
    console.log('Error getting Flex Status: ', e);
    return false;
  }
}

export async function postFlexStatus(payload) {
  try {
    console.log('posting flex status: ', payload);
    const resp = await HttpService.post(`/flex-setup/status`, payload);
    console.log('flex status posted: ', resp.data);
    return resp;
  } catch (e) {
    console.log('error adding flex status: ', e);
    return false;
  }
}
export async function deleteFlexStatus(payload) {
  try {
    console.log('delete flex status', payload);
    const resp = await HttpService.delete(
      `/flex-setup?userId=` + payload.userId,
    );
    return true;
  } catch (e) {
    console.log('error deleting flex status: ', e);
    return false;
  }
}

export async function getFlexTokens({ email, password }) {
  try {
    console.log('get flex tokens', email, password);
    const resp = await HttpService.post(`/flex-setup/tokens`, {
      email,
      password,
    });

    console.log('response: ', resp.data);
    return resp.data;
  } catch (e) {
    console.log('error deleting flex status: ', e);
    throw e;
  }
}

export async function postFlexCredentials({
  email,
  password,
  userId,
  continent,
}) {
  try {
    const resp = await HttpService.post(apiUrlV3, {
      email,
      password,
      userId,
      continent,
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
}
