import { Tour, Step } from 'react-rtg';
import { Text, Button, useColorMode } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import './Tour.css';

const FilterTour = ({ onWait, loader, locations, onTourFinish }) => {
  const [isOpen, setOpen] = useState(false);

  const { colorMode } = useColorMode();

  useEffect(() => {
    const modal = document.querySelector('.rtg__modal');
    if (modal) {
      modal.style.background = colorMode === 'light' ? 'white' : '#101218';
    }
  }, [colorMode]);

  return (
    <Tour
      isOpen={isOpen}
      id="filterTour"
      closeButton={false}
      controlNumbers={false}
      controls={() => {}}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      badgeClassName="bargain-rtg-badge"
      className="bargain-tour"
    >
      <Step className="bargain-tour" selector="#minPay" placement="bottom">
        <Text>
          Select the minimum price per block you would like to grab, it includes
          not tip. For example, a 2 hours block at Whole Foods that is $30 would
          have not been grabbed by a Minimum Price of $35.
        </Text>
      </Step>
      <Step selector="#timeBuffer" placement="bottom">
        <Text>
          Select the amount of time you want to have until a block starts
        </Text>
      </Step>
      <Step
        onWait={onWait}
        selector="#locations"
        placement="top"
        pinOffset={20}
      >
        <Text>
          Pick the locations you would like to work at. (You must select at
          least 1.)
        </Text>
        <Button
          onClick={onTourFinish}
          isLoading={loader.isLoading()}
          disabled={loader.isLoading() || !locations.length}
          colorScheme="blue"
          mt={4}
        >
          Done Picking
        </Button>
      </Step>
    </Tour>
  );
};

export default FilterTour;
