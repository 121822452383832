import { Box, Text } from '@chakra-ui/layout';
import {
  Button,
  Collapse,
  Flex,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useToggle } from '../utils/hooks';
import AllBlocks from './AllBlocks';
import BlockItem from './BlockItem';

const money = 2003;

function StatsCard({ money, type, blocks }) {
  const boxBg = useColorModeValue('white', 'rgb(27 34 46)');
  const showBlocks = useToggle(false);
  const { colorMode } = useColorMode();

  return (
    <Box
      bg={boxBg}
      pl={3}
      pr={3}
      pt={4}
      pb={4}
      borderRadius="12px"
      width="100%"
    >
      <Text fontSize="xl" color="gray.500" fontWeight="semibold">
        {type}
      </Text>
      <Flex alignItems="flex-end">
        <Text fontWeight="semibold" fontSize="3xl">
          ${Number(money).toLocaleString()}
        </Text>
        <Text fontSize="md" fontWeight="medium" color="gray.500" ml={2}>
          this week
        </Text>
      </Flex>

      <Flex
        mt="10px"
        mb="10px"
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text fontSize="lg" color="gray.500" fontWeight="semibold">
          Blocks
        </Text>
        <Button
          isDisabled={!blocks.length}
          onClick={showBlocks.toggle}
          size="sm"
          variant="ghost"
        >
          see all
        </Button>
      </Flex>
      {blocks[0] && (
        <BlockItem
          showReason={type === 'Rejected'}
          type={type}
          block={blocks[0]}
          colorMode={colorMode}
        />
      )}
      <AllBlocks showBlocks={showBlocks} blocks={blocks} type={type} />
    </Box>
  );
}

export default StatsCard;
