import { createContext, useContext, useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Slide,
} from '@chakra-ui/react';

const NotifyContext = createContext();

export function useNotifyContext() {
  return useContext(NotifyContext);
}

function NotifyContextProvider({ children }) {
  const [options, setOptions] = useState(false);
  const [show, setShow] = useState(false);

  function formOptions(status, description) {
    const obj = {
      status,
      description,
    };

    setOptions(obj);
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 5000);
  }

  const showMap = {
    error: (...args) => formOptions('error', ...args),
    success: (...args) => formOptions('success', ...args),
    info: (...args) => formOptions('info', ...args),
  };

  return (
    <NotifyContext.Provider value={showMap}>
      <Slide direction="bottom" in={show} style={{ zIndex: 10 }}>
        <Alert
          status={options ? options.status : 'info'}
          marginLeft="10px"
          mb="10px"
          maxW="400px"
          zIndex="99999"
        >
          <AlertIcon />
          {options && options.description && (
            <AlertDescription>{options.description}</AlertDescription>
          )}
        </Alert>
      </Slide>
      {children}
    </NotifyContext.Provider>
  );
}

export default NotifyContextProvider;
