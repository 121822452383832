import { Text } from '@chakra-ui/layout';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useNotifyContext } from '../context/NotifyContext';
import { useUserContext } from '../context/UserContext';
import {
  deleteFlexStatus,
  getFlexStatus,
  postFlexCredentials,
  postFlexStatus,
} from '../services/FlexSetUpService';
import { validateEmail } from '../utils/generic';
import { useFormInput, useLoading, useToggle } from '../utils/hooks';
import PageWrapper from './PageWrapper';

function Approval() {
  const interval = useRef();
  const [time, setTime] = useState(600);

  useEffect(() => {
    if (!interval.current) {
      interval.current = setInterval(() => {
        setTime(state => (state -= 1));
      }, 1000);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = false;
      }
    };
  }, []);

  function formatSeconds(input) {
    const min = Math.floor(input / 60);

    let realMin = '';

    if (min > 9) {
      realMin = String(min);
    } else {
      realMin += '0' + String(min);
    }

    const seconds = input - min * 60;

    let realSeconds = '';

    if (seconds > 9) {
      realSeconds = String(seconds);
    } else {
      realSeconds += '0' + String(seconds);
    }

    return realMin + ':' + realSeconds;
  }

  return (
    <Stack spacing={12} display="flex" flexDir="column" alignItems="center">
      <Text fontSize="xl" textAlign="center" mt={10}>
        You have received a text message or email from Amazon Flex. Find the
        message and approve it.
      </Text>

      <Spinner size="xl" />
      <Text fontWeight="semibold" mt={4}>
        Waiting for approval
      </Text>
      <Text fontSize="2xl" fontWeight="bold">
        {formatSeconds(time)}
      </Text>
    </Stack>
  );
}

function Otp({ onOtpSubmit, loader }) {
  const otp = useFormInput('');

  return (
    <Stack mt={4} spacing={4}>
      <Text>
        An OTP (One Time Passcode) was sent from Amazon Flex to your email or as
        a text message. Please find the code and enter it here.
      </Text>
      <FormControl>
        <FormLabel>Otp Code</FormLabel>
        <Input placeholder="000000" {...otp} />
      </FormControl>
      <Button
        onClick={() => onOtpSubmit(otp.value)}
        isLoading={loader.isLoading()}
        disabled={!otp.value || loader.isLoading()}
        colorScheme="blue"
      >
        Send Code
      </Button>
    </Stack>
  );
}

function OtpChoice({ choices, onChoiceSubmit, loader }) {
  const [currChoice, setCurrentChoice] = useState(0);

  function onChange(val) {
    setCurrentChoice(Number(val));
  }
  return (
    <Stack mt={4} spacing={4}>
      <Text>
        Amazon Flex needs to send you a code to verify your account. Where would
        you like this code to be sent to?
      </Text>
      <Text fontWeight="semibold" fontSize="xl">
        Choices
      </Text>
      <RadioGroup value={currChoice} onChange={onChange} size="lg">
        <Stack spacing={3}>
          {choices.map((choice, idx) => (
            <Radio value={idx}>{choice}</Radio>
          ))}
        </Stack>
      </RadioGroup>
      <Button
        disabled={loader.isLoading()}
        isLoading={loader.isLoading()}
        onClick={() => onChoiceSubmit(currChoice)}
        colorScheme="blue"
      >
        Submit Choice
      </Button>
    </Stack>
  );
}

const VIEWS = {
  CREDENTIALS: 'CREDENTIALS',
  OTP: 'OTP',
  APPROVAL: 'APPROVAL',
  CONTINENT: 'CONTINENT',
  OTP_CHOICE: 'OTP_CHOICE',
};

function FlexSetUp() {
  const email = useFormInput('', validateEmail);
  const password = useFormInput('');
  const user = useUserContext();
  const showPassword = useToggle(false);
  const notify = useNotifyContext();
  const history = useHistory();
  const [view, setView] = useState(VIEWS.CREDENTIALS);
  const [choices, setChoices] = useState([
    'Phone number ends with 4954',
    'Email ends with fran@gmail.com',
    'Phone Number ends with 8443',
  ]);

  const interval = useRef(false);

  const loader = useLoading(false);

  async function flexInterval() {
    try {
      const status = await getFlexStatus(user.userId);
      console.log('status: ', status);

      if (status.type === 'OTP') {
        if (status.view !== VIEWS.OTP) {
          setView(VIEWS.OTP);
        }

        loader.stop();
        return;
      }

      if (status.type === 'OTP_CHOICE') {
        if (status.view !== VIEWS.OTP_CHOICE) {
          setChoices(status.data.choices);
          setView(VIEWS.OTP_CHOICE);
        }
        loader.stop();
        return;
      }

      if (status.type === 'APPROVAL') {
        if (view !== VIEWS.APPROVAL) {
          setView(VIEWS.APPROVAL);
        }
        return;
      }

      if (status.type === 'COMPLETE') {
        await user.updateUser();
        history.push('/');
      }

      if (status.type === 'ERROR_INVALID_ACCOUNT') {
        loader.stop();
        notify.error(status.data.text);
        setView(VIEWS.CREDENTIALS);
        return;
      }

      if (status.type === 'ERROR') {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = false;
        }

        if (status.text === 'UNKNOWN ERROR') {
          notify.error("It seems you don't have a valid Amazon Flex account.");
        } else {
          notify.error(status.text);
        }

        loader.stop();
      }
    } catch (e) {
      notify.error('Error getting flex status: ' + e.message);
    }
  }

  async function onOtpSubmit(otp) {
    loader.start();

    try {
      const payload = {
        type: 'OTP-CODE',
        body: {
          code: otp,
        },
        userId: user.userId,
      };
      await postFlexStatus(payload);
    } catch (e) {
      notify.error('Error submitting OTP: ' + e.message);
      loader.stop();
    }
  }

  async function onOtpChoiceSubmit(choice) {
    loader.start();

    try {
      const payload = {
        type: 'OTP-CHOICE_RESPONSE',
        body: {
          code: choice,
        },
        userId: user.userId,
      };
      await postFlexStatus(payload);
    } catch (e) {
      notify.error('Error submitting OTP choice: ' + e.message);
      loader.stop();
    }
  }

  async function handleVerify() {
    loader.start();

    try {
      const response = await postFlexCredentials({
        email: email.value,
        password: password.value,
        userId: user.userId,
        continent: 'AMERICA',
      });

      try {
        await deleteFlexStatus({ userId: user.userId });
      } catch (e) {}

      interval.current = setInterval(flexInterval, 5000);
    } catch (e) {
      notify.error('Error verifying credentials: ' + e.message);
    }
  }

  return (
    <PageWrapper>
      <Text fontSize="2xl" fontWeight="semibold" mt={4}>
        Configure your account
      </Text>

      {view === VIEWS.CREDENTIALS && (
        <Stack spacing={6} mt={4}>
          <FormControl>
            <FormLabel>Amazon Flex Email</FormLabel>
            <Input placeholder="driver@mail.com" type="email" {...email} />
          </FormControl>
          <FormControl>
            <FormLabel>Amazon Flex Password</FormLabel>
            <InputGroup>
              <Input
                placeholder="**yourFlexPassword**"
                type={showPassword.isActive() ? 'text' : 'password'}
                {...password}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={showPassword.toggle}>
                  {showPassword.isActive() ? 'hide' : 'show'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormHelperText>
              Must be the same as your amazon flex account
            </FormHelperText>
          </FormControl>
          <Button
            disabled={
              loader.isLoading() ||
              email.isInvalid ||
              !email.value ||
              !password.value
            }
            onClick={handleVerify}
            isLoading={loader.isLoading()}
            colorScheme="blue"
          >
            Verify
          </Button>
        </Stack>
      )}
      {view === VIEWS.APPROVAL && <Approval />}
      {view === VIEWS.OTP && <Otp onOtpSubmit={onOtpSubmit} loader={loader} />}
      {view === VIEWS.OTP_CHOICE && (
        <OtpChoice
          loader={loader}
          onChoiceSubmit={onOtpChoiceSubmit}
          choices={choices}
        />
      )}
    </PageWrapper>
  );
}

export default FlexSetUp;
