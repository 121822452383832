import HttpService from './HttpService';

export async function getInvites() {
  try {
    const response = await HttpService.get('/invite/all');
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function sendInvite(phoneNumber) {
  try {
    const response = await HttpService.post('/invite', { phoneNumber });
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function getInviteById(inviteId) {
  try {
    const response = await HttpService.get('/invite' + '?inviteId=' + inviteId);
    return response.data;
  } catch (e) {
    throw e;
  }
}
