import HttpService from './HttpService';

export async function getProducts() {
  try {
    const response = await HttpService.get('/v2/products');
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function getPaymentIntent(priceId) {
  try {
    const response = await HttpService.post('/v2/payments', { priceId });
    return response.data;
  } catch (e) {
    throw e;
  }
}
