import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import PageWrapper from '../../components/PageWrapper';
import { useNotifyContext } from '../../context/NotifyContext';
import { login, register } from '../../services/AuthService';
import { useFormInput, useLoading } from '../../utils/hooks';
import { cleanPhoneNumber } from '../../utils/generic';
import { getInviteById } from '../../services/InvitationService';

function Register() {
  const [show, setShow] = useState(false);
  const name = useFormInput('');
  const phoneNumber = useFormInput('');
  const password = useFormInput('');
  const confirmPassword = useFormInput('');
  const notify = useNotifyContext();
  const loader = useLoading();

  const isInvite = window.location.href.includes('/register/invite');

  const [invitation, setInvitation] = useState(null);

  async function loadInvitation(inviteId) {
    try {
      const response = await getInviteById(inviteId);
      setInvitation(response);
    } catch (e) {
      console.log('ERROR getting invite: ', e.message);
    }
  }

  useEffect(() => {
    if (isInvite) {
      const inviteId = window.location.href.split('/register/invite/')[1];
      loadInvitation(inviteId);
    }
  }, []);

  async function handleRegisterClick() {
    if (password.value !== confirmPassword.value) {
      notify.error('Your passwords must match!');
      return;
    }

    loader.start();

    let realPhoneNumber = cleanPhoneNumber(phoneNumber.value);

    if (invitation) {
      realPhoneNumber = invitation.invited;
    }

    try {
      const registerResponse = await register(
        realPhoneNumber,
        password.value,
        name.value,
        invitation && invitation.inviteId,
      );

      if (registerResponse && registerResponse.statusCode !== 200) {
        let { body } = registerResponse;

        if (typeof body === 'string') {
          body = JSON.parse(body);
        }
        throw new Error(body.message);
      }

      await login({ phoneNumber: phoneNumber.value, password: password.value });

      window.location.reload();
    } catch (e) {
      notify.error('Error registering user: ' + e.message);
      loader.stop();
    }
  }

  return (
    <PageWrapper alignItems="center" justifyContent="center">
      <Stack minW="300px" spacing={4} display="flex" flexDir="column">
        <Text textAlign="center" fontSize="3xl" fontWeight="semibold">
          create your account
        </Text>
        <FormControl isRequired id="name">
          <InputGroup>
            <Input placeholder="name" {...name} />
          </InputGroup>
        </FormControl>
        <FormControl isRequired id="phoneNumber">
          <InputGroup>
            <InputLeftAddon children="+1" />
            {invitation ? (
              <Input
                placeholder="phone number"
                type="tel"
                value={invitation.invited}
                isDisabled={true}
              />
            ) : (
              <Input placeholder="phone number" type="tel" {...phoneNumber} />
            )}
          </InputGroup>
        </FormControl>
        <FormControl isRequired id="password">
          <InputGroup>
            <Input
              placeholder="password"
              type={show ? 'text' : 'password'}
              {...password}
            />
            <InputRightElement width="4.5rem">
              <Button
                h="1.75rem"
                size="sm"
                onClick={() => setShow(state => !state)}
              >
                {show ? 'hide' : 'show'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl isRequired id="confirmPassword">
          <InputGroup>
            <Input
              placeholder="confirm password"
              type={show ? 'text' : 'password'}
              {...confirmPassword}
            />
            <InputRightElement width="4.5rem">
              <Button
                h="1.75rem"
                size="sm"
                onClick={() => setShow(state => !state)}
              >
                {show ? 'hide' : 'show'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button
          size="sm"
          colorScheme="blue"
          onClick={handleRegisterClick}
          isLoading={loader.isLoading()}
          isDisabled={
            !name.value ||
            (invitation ? !invitation.invited : !phoneNumber.value) ||
            !password.value ||
            !confirmPassword.value ||
            loader.isLoading()
          }
        >
          register
        </Button>
      </Stack>
    </PageWrapper>
  );
}

export default Register;
