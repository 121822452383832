import HttpService from './HttpService';

export async function getLocations(user) {
  try {
   const response = await HttpService.get("/flex-zones?continent=" + (user.continent || 0)  +  "&country=" +  "United%20States"  + "&zone=" + user.region);
      const locations = [];
      if (response.data && response.data.services) {
        for (let i = 0; i < response.data.services.length; i++) {
          const area = response.data.services[i];

          locations.push({
            id: area.id,
            label: area.name,
            address: area?.pickUpLocation?.address?.address1
          });
        }
      }

    return locations;
  } catch(e) {
    throw e;
  }

}
