import { Text } from '@chakra-ui/layout';
import {
  Box,
  Image,
  Button,
  Flex,
  Stack,
  useColorModeValue,
  Checkbox,
} from '@chakra-ui/react';
import React from 'react';
import { useCardBg, useToggle } from '../utils/hooks';
import SelectiveImg from '../assets/selective.svg';
import WalletImg from '../assets/wallet.svg';
import ConfirmationDialog from './ConfirmationDialog';

const FILTERS = [
  {
    title: 'worker',
    description:
      'Your filters will be very open. Lower paid blocks but, you will get the most amount of work with this option.',
    minPrice: 10,
    timeBuffer: 15,
    img: WalletImg,
  },
  {
    title: 'selective',
    description:
      'Your filters will be more strict. Higher paid blocks, you will get less work but the quality will be better.',
    minPrice: 40,
    timeBuffer: 30,
    img: SelectiveImg,
  },
];

function FilterCard({ title, dialog, description, minPrice, timeBuffer, img }) {
  const boxBg = useColorModeValue('white', 'rgb(27 34 46)');

  return (
    <React.Fragment>
      <Flex flexDir="column" background={boxBg} p={4} borderRadius="10px">
        <Flex flexDir="row" alignItems="center" justifyContent="space-between">
          <Text visibility="hidden">Hello</Text>
          <Text fontWeight="semibold" fontSize="2xl" textAlign="center">
            {title}
          </Text>
          <Checkbox onChange={dialog.show} size="lg" colorScheme="blue">
            Active
          </Checkbox>
        </Flex>
        <Image src={img} mt={2} mb={2} boxSize="250px" alignSelf="center" />
        <Text
          fontWeight="semibold"
          fontStyle="italic"
          textAlign="center"
          width="90%"
          alignSelf="center"
          fontSize="md"
        >
          {description}
        </Text>
        <Flex justifyContent="space-between" mt={6}>
          <Stack spacing={1}>
            <Text fontSize="xs" textAlign="center">
              Minimum Price
            </Text>
            <Text textAlign="center" fontWeight="semibold">
              ${minPrice} per block
            </Text>
          </Stack>
          <Stack spacing={1}>
            <Text fontSize="xs" textAlign="center">
              Time until block starts
            </Text>
            <Text textAlign="center" fontWeight="semibold">
              {timeBuffer} minutes
            </Text>
          </Stack>
        </Flex>
      </Flex>
    </React.Fragment>
  );
}

function BasicFilters({ switchToAdvanced }) {
  const workerDialog = useToggle();
  const selectiveDialog = useToggle();

  const dialogs = { worker: workerDialog, selective: selectiveDialog };
  return (
    <React.Fragment>
      <ConfirmationDialog
        isOpen={workerDialog.isActive()}
        onClose={workerDialog.hide}
        title="worker filter"
        body="Are you sure you want to turn on the worker filter?"
        actionText="Yes"
      />
      <ConfirmationDialog
        isOpen={selectiveDialog.isActive()}
        onClose={selectiveDialog.hide}
        title="selective filter"
        body="Are you sure you want to turn on the selective filter?"
        actionText="Yes"
      />
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={8}
      >
        <Text fontWeight="semibold">Easy Filters</Text>
        <Button
          onClick={switchToAdvanced}
          size="sm"
          colorScheme="blue"
          variant="link"
        >
          Switch to Advanced Filters
        </Button>
      </Flex>
      <Stack spacing={4}>
        {FILTERS.map(filter => (
          <FilterCard {...filter} dialog={dialogs[filter.title]} />
        ))}
      </Stack>
    </React.Fragment>
  );
}

export default BasicFilters;
