import { Stack, Text } from '@chakra-ui/layout';
import {
  Button,
  Flex,
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Skeleton,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BsArrowLeft, BsBack } from 'react-icons/bs';
import InviteItem from '../components/InviteItem';
import { useNotifyContext } from '../context/NotifyContext';
import { getInvites, sendInvite } from '../services/InvitationService';
import { useFormInput, useLoading, useViewController } from '../utils/hooks';

function InviteView({ onInviteSent, goBack }) {
  const loader = useLoading();
  const phoneNumber = useFormInput('');

  async function onSendInviteClick() {
    try {
      loader.start();
      const response = await sendInvite(phoneNumber.value);
      onInviteSent();
    } catch (e) {
      loader.stop();
      throw e;
    }
  }

  return (
    <Flex flexDir="column">
      <Flex flexDir="row" mb={8} alignItems="center">
        <IconButton icon={<BsArrowLeft />} onClick={goBack} />
        <Text fontWeight="semibold" fontSize="lg" ml={4}>
          Fill out their information
        </Text>
      </Flex>

      <FormControl isRequired id="phoneNumber">
        <InputGroup>
          <InputLeftAddon children="+1" />
          <Input
            disabled={loader.isLoading()}
            {...phoneNumber}
            placeholder="phone number"
            type="tel"
          />
        </InputGroup>
      </FormControl>
      <Button
        isDisabled={
          phoneNumber.value.length !== 10 ||
          loader.isLoading() ||
          !phoneNumber.value
        }
        isLoading={loader.isLoading()}
        colorScheme="blue"
        onClick={onSendInviteClick}
        mt={4}
      >
        Send Invitation
      </Button>
    </Flex>
  );
}

function Invite() {
  const boxBg = useColorModeValue('white', 'rgb(27 34 46)');
  const [invites, setInvites] = useState([]);
  const notify = useNotifyContext();
  const loader = useLoading(true);
  const viewController = useViewController(['MAIN', 'SEND'], 'MAIN');

  async function loadData() {
    try {
      loader.start();
      const response = await getInvites();
      setInvites(response);
      loader.stop();
    } catch (e) {
      notify.error('Error fetching invitations: ' + e.message);
      loader.stop();
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <React.Fragment>
      {viewController.current === viewController.VIEWS.SEND && (
        <InviteView
          onInviteSent={() => {
            notify.success('Invite sent');
            viewController.set(viewController.VIEWS.MAIN);
            loadData();
          }}
          goBack={() => {
            viewController.set(viewController.VIEWS.MAIN);
          }}
        />
      )}
      {viewController.current === viewController.VIEWS.MAIN && (
        <Stack spacing={8}>
          <Flex
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontWeight="semibold" fontSize="lg">
              Invite your friends!
            </Text>
            <Button
              onClick={() => viewController.set(viewController.VIEWS.SEND)}
              colorScheme="blue"
              variant="solid"
            >
              Invite
            </Button>
          </Flex>
          <Stack>
            <Text fontWeight="semibold" fontSize="lg">
              Received Rewards
            </Text>
            <Text fontWeight="semibold" fontSize="5xl">
              {invites.filter(item => item.status === 'REGISTERED - PAID')
                .length * 7}{' '}
              days
            </Text>
          </Stack>
          <Flex mb={4} p={4} bg={boxBg} borderRadius="12px" flexDir="column">
            <Text mb={2} textDecoration="underline" fontWeight="bold">
              How to get rewards?
            </Text>
            <Text fontSize="lg">
              Invite a person and after they pay, you get a week for free!
            </Text>
          </Flex>
          <Text fontWeight="semibold" fontSize="lg">
            All my invites
          </Text>

          {loader.isLoading() && (
            <Stack spacing={6} mt={4}>
              <Skeleton height={20} />
              <Skeleton height={20} />
            </Stack>
          )}

          {!loader.isLoading() && !!invites.length && (
            <Stack>
              {invites.map(invite => (
                <InviteItem {...invite} />
              ))}
            </Stack>
          )}
          {!loader.isLoading() && !Boolean(invites.length) && (
            <Text textAlign="center">No invites have been sent yet</Text>
          )}
        </Stack>
      )}
    </React.Fragment>
  );
}

export default Invite;
