import { Box, Flex } from '@chakra-ui/layout';
import React from 'react';

function ContentWrapper({ children }) {
  return (
    <Flex width="100%" flexDir="column" alignSelf="center" maxW="600px">
      {children}
    </Flex>
  );
}

export default ContentWrapper;
