import HttpService from './HttpService';


export async function getDashboardData() {
  try {
    const response = await HttpService.get("/bargain/dashboard?service=FLEX");
    return response.data;
  } catch(e) {
    throw e;
  }
}
