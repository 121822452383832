import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getLocations } from '../services/LocationService';
import { useUserContext } from './UserContext';

const LocationContext = createContext();

export function useLocationContext() {
  return useContext(LocationContext);
}

function LocationContextProvider({ children }) {
  const [locations, setLocations] = useState(false);
  const user = useUserContext();

  async function loadData() {
    if (!user) return;

    try {
      const locationResponse = await getLocations(user);
      setLocations(locationResponse);
    } catch (e) {}
  }

  useEffect(() => {
    loadData();
  }, [user]);

  const locationMap = useMemo(() => {
    if (!locations) return {};

    const map = {};

    for (const location of locations) {
      map[location.id] = location.label;
    }

    return map;
  }, [locations]);

  return (
    <LocationContext.Provider value={{ locations, locationMap }}>
      {children}
    </LocationContext.Provider>
  );
}

export default LocationContextProvider;
