import { useColorModeValue } from '@chakra-ui/react';
import { useState, useMemo } from 'react';

export function useCardBg() {
  const boxBg = useColorModeValue('#f3f7fb', 'rgb(38,48,64)');
  return boxBg;
}

export function useViewController(views, curr) {
  const [currentView, setCurrentView] = useState(curr);

  const VIEWS = useMemo(() => {
    const res = {};

    for (const view of views) {
      res[view] = view;
    }

    return res;
  }, []);
  return { set: setCurrentView, current: currentView, VIEWS };
}

export function useFormInput(defaultValue, validator) {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(false);

  function onChange({ target }) {
    if (validator) {
      const valid = !validator(target.value);
      setError(valid);
    }
    setValue(target.value);
  }

  return { onChange, value, isInvalid: error };
}

export function useLoading(defaultValue = false) {
  const [loading, setLoading] = useState(defaultValue);

  function stop() {
    setLoading(false);
  }

  function start() {
    setLoading(true);
  }

  function toggle() {
    setLoading(state => !state);
  }

  function isLoading() {
    return loading;
  }

  return { stop, start, toggle, isLoading };
}

export function useToggle(defaultValue = false) {
  const [active, setActive] = useState(defaultValue);

  function hide() {
    setActive(false);
  }

  function show() {
    setActive(true);
  }

  function toggle() {
    setActive(state => !state);
  }

  function isActive() {
    return active;
  }

  return { show, hide, toggle, isActive };
}
