import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button, ModalBody, ModalCloseButton, Text } from '@chakra-ui/react';
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useNotifyContext } from '../context/NotifyContext';
import { useLoading } from '../utils/hooks';
import { useUserContext } from '../context/UserContext';
import { sleep } from '../utils/generic';

const stripePromise = loadStripe(
  'pk_live_51Jphp4HyCJ4MRtI6WYcMGFAD4k0nDgqpxfhGoJkJmxscNTik9sPTcAbYT4Ctoap3OQ4uLjtUQIV5Zrmyc4fFYnML00FMx4ovOy',
);

function PaymentModal({ isOpen, onClose, title, price }) {
  const stripe = useStripe();
  const elements = useElements();
  const notify = useNotifyContext();
  const loader = useLoading();
  const user = useUserContext();

  async function onSubmit() {
    if (!stripe || !elements) {
      return;
    }

    loader.start();
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
    });

    if (error) {
      notify.error(error.message);
      loader.stop();
    } else {
      notify.success('Payment succesfull');
      await sleep(3 * 1000);
      await user.updateUser();
      loader.stop();
      onClose();
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }
  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PaymentElement />
          <Text mt={8} fontWeight="semibold" textAlign="end" fontSize="xl">
            Total: {price}
          </Text>
          <Button
            isDisabled={!stripe || loader.isLoading()}
            isLoading={loader.isLoading()}
            onClick={onSubmit}
            colorScheme="blue"
            mt={4}
            isFullWidth
          >
            Submit
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function PaymentModalWrapper({ clientSecret, ...rest }) {
  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentModal {...rest} />
    </Elements>
  );
}

export default PaymentModalWrapper;
