import { Text } from '@chakra-ui/layout';
import { Button, Flex, FormControl, Input, InputGroup } from '@chakra-ui/react';
import React from 'react';
import ContentWrapper from '../components/ContentWrapper';
import PageWrapper from '../components/PageWrapper';
import { useUserContext } from '../context/UserContext';
import { logOut } from '../services/AuthService';

function Profile() {
  const user = useUserContext();

  function onLogOutClick() {
    logOut();
    window.location.reload();
  }

  return (
    <PageWrapper>
      <ContentWrapper>
        <Text fontSize="3xl" mt={4} fontWeight="bold">
          Profile
        </Text>
        <Flex alignItems="center" justifyContent="space-between" mt={6}>
          <Text fontWeight="bold" fontSize="lg">
            Name
          </Text>
          <FormControl w={'200px'} isRequired id="name" mt={4}>
            <InputGroup>
              <Input
                variant="filled"
                placeholder="Name"
                value={user.name}
                isDisabled
              />
            </InputGroup>
          </FormControl>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="lg">
            Phone Number
          </Text>
          <FormControl w={'200px'} isRequired id="name" mt={4}>
            <InputGroup>
              <Input
                variant="filled"
                placeholder="Phone Number"
                isDisabled
                value={user.phoneNumber}
              />
            </InputGroup>
          </FormControl>
        </Flex>
        <Button
          onClick={onLogOutClick}
          variant="link"
          colorScheme="blue"
          mt={8}
        >
          Log Out
        </Button>
      </ContentWrapper>
    </PageWrapper>
  );
}

export default Profile;
