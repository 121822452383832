export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function sleep(timeout) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
}

export function cleanPhoneNumber(phoneNumber) {
  let realPhoneNumber;

  realPhoneNumber = phoneNumber
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/-/g, '')
    .replace(/ /g, '')
    .trim();

  if (!realPhoneNumber.includes('+1')) {
    if (realPhoneNumber.includes('+')) {
      realPhoneNumber = realPhoneNumber.replace(/\+/g, '+1');
    } else {
      realPhoneNumber = '+1' + realPhoneNumber;
    }
  }

  return realPhoneNumber;
}
