import axios from 'axios';
import {
  checkIfTokenIsExpired,
  getAuthToken,
  refreshToken,
} from './AuthService';

export const API_ENDPOINT =
  'https://hocx8gfype.execute-api.us-east-1.amazonaws.com/prd';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async function(config) {
    let newUrl = API_ENDPOINT + config.url;

    try {
      let token = getAuthToken();
      if (token) {
        const isTokenExpired = checkIfTokenIsExpired(token);
        if (isTokenExpired) {
          console.log('IS TOKEN ExPIRED: ', isTokenExpired);
          await refreshToken();
          // TODO
        }
        config.headers.authorization = token;
      }
    } catch (e) {}

    config.url = newUrl;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);

export default axiosInstance;
