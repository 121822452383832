import { Spinner, Stack, Text } from '@chakra-ui/react';
import { createContext, useContext, useEffect, useState } from 'react';
import PageWrapper from '../components/PageWrapper';
import { checkSession } from '../services/AuthService';
import { getUser } from '../services/UserService';
import { useLoading } from '../utils/hooks';

const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const loader = useLoading(true);

  async function loadData() {
    const session = checkSession();
    if (!session) {
      loader.stop();
      return;
    }

    try {
      const user = await getUser();

      createHotJarConfig(user);

      setUser(user);
      loader.stop();
    } catch (e) {
      loader.stop();
    }
  }

  function createHotJarConfig(user) {
    if (!user) return;

    const { phoneNumber, referralCode, brand, userId, name, createDate } = user;

    if (!window.hj) return;

    window.hj('identify', userId, {
      createDate,
      phoneNumber,
      name,
      referralCode,
      brand,
    });
  }

  async function updateUser() {
    try {
      const user = await getUser();
      setUser(user);
    } catch (e) {
      // TODO
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  if (loader.isLoading())
    return (
      <PageWrapper>
        <Stack
          height="73vh"
          display="flex"
          flexDir="column"
          justifyContent="center"
          spacing={12}
        >
          <Spinner size="xl" alignSelf="center" />
          <Text fontSize="4xl" textAlign="center">
            Loading...
          </Text>
        </Stack>
      </PageWrapper>
    );

  return (
    <UserContext.Provider value={user ? { ...user, updateUser } : null}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
