import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import PageWrapper from '../components/PageWrapper';
import Dashboard from './Dashboard/Dashboard';
import Filters from './Filters';
import { useUserContext } from '../context/UserContext';
import { TabContextProvider, useTabContext } from '../context/TabContext';
import Billing from '../components/Billing';
import moment from 'moment-timezone';
import { isPlanExpired } from '../utils/billing';
import ContentWrapper from '../components/ContentWrapper';
import Invite from './Invite';

function Home({ shouldBeInFilters, shouldBeInBilling }) {
  const { currentTab, setCurrentTab } = useTabContext();

  return (
    <PageWrapper>
      <Tabs onChange={index => setCurrentTab(index)} index={currentTab}>
        <TabList>
          <Tab isDisabled={shouldBeInFilters || shouldBeInBilling}>Home</Tab>
          <Tab isDisabled={shouldBeInBilling}>Filters</Tab>
          <Tab isDisabled={shouldBeInFilters}>Billing</Tab>
          <Tab isDisabled={shouldBeInFilters || shouldBeInBilling}>Invite</Tab>
        </TabList>
        <TabPanels>
          <TabPanel display="flex" flexDir="column">
            <ContentWrapper>
              <Dashboard />
            </ContentWrapper>
          </TabPanel>
          <TabPanel display="flex" flexDir="column">
            <ContentWrapper>
              <Filters shouldBeInFilters={shouldBeInFilters} />
            </ContentWrapper>
          </TabPanel>
          <TabPanel display="flex" flexDir="column">
            <ContentWrapper>
              <Billing />
            </ContentWrapper>
          </TabPanel>
          <TabPanel display="flex" flexDir="column">
            <ContentWrapper>
              <Invite />
            </ContentWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageWrapper>
  );
}

function HomeWrapper() {
  const user = useUserContext();

  const shouldBeInFilters = useMemo(() => {
    if (user.setupComplete) return false;
    return true;
  }, [user]);

  const shouldBeInBilling = useMemo(() => {
    return isPlanExpired(user.planExpirationDate);
  }, [user]);

  const initialTab = useMemo(() => {
    const url = new URL(window.location.href);

    try {
      const t = url.searchParams.get('t');
      if (t) {
        return Number(t);
      }

      return false;
    } catch (e) {
      return false;
    }
  }, []);

  return (
    <TabContextProvider
      initialTab={
        shouldBeInBilling
          ? 2
          : shouldBeInFilters
          ? 1
          : initialTab !== false
          ? initialTab
          : 0
      }
    >
      <Home
        shouldBeInBilling={shouldBeInBilling}
        shouldBeInFilters={shouldBeInFilters}
      />
    </TabContextProvider>
  );
}

export default HomeWrapper;
