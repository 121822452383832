import { Stack, Text } from '@chakra-ui/layout';
import { Button, Flex, useColorModeValue, useModal } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNotifyContext } from '../context/NotifyContext';
import { useUserContext } from '../context/UserContext';
import { getPaymentIntent, getProducts } from '../services/BillingService';
import {
  calculateDaysLeftOfPlan,
  getNextBillingDate,
  isPlanExpired,
} from '../utils/billing';
import { useLoading, useToggle } from '../utils/hooks';
import PaymentModal from './PaymentModal';
import moment from 'moment-timezone';
function BillingCard({ priceText, length, priceId }) {
  const boxBg = useColorModeValue('white', 'rgb(27 34 46)');
  const user = useUserContext();

  const paymentModal = useToggle(false);
  const nextBillingDate = getNextBillingDate(user.planExpirationDate, length);
  const notify = useNotifyContext();
  const pi = useRef(false);
  const loader = useLoading();

  async function onPurchaseClick() {
    let paymentIntent;
    loader.start();
    try {
      paymentIntent = await getPaymentIntent(priceId);
      pi.current = paymentIntent;
      paymentModal.show();
    } catch (e) {
      notify.error('Error getting product information');
    }
    loader.stop();
  }

  return (
    <React.Fragment>
      {pi.current && (
        <PaymentModal
          clientSecret={pi.current}
          isOpen={paymentModal.isActive()}
          price={priceText}
          onClose={paymentModal.hide}
          title={length + ' days plan'}
        />
      )}
      <Stack p={6} borderRadius="12px" background={boxBg} spacing={5}>
        <Stack spacing={2}>
          <Text textAlign="center" fontSize="4xl" fontWeight="bold">
            {priceText}
          </Text>
          <Text textAlign="center" fontSize="lg" fontWeight="semibold">
            for {length} days
          </Text>
        </Stack>
        <Text fontWeight="semibold" textAlign="center">
          Next payment date: {nextBillingDate}
        </Text>
        <Button
          isLoading={loader.isLoading()}
          isDisabled={loader.isLoading()}
          onClick={onPurchaseClick}
          colorScheme="blue"
          size="sm"
        >
          Purchase
        </Button>
      </Stack>
    </React.Fragment>
  );
}

function Billing() {
  const user = useUserContext();
  const notify = useNotifyContext();
  const loader = useLoading();

  const daysLeft = calculateDaysLeftOfPlan(user.planExpirationDate);

  const [products, setProducts] = useState([]);

  async function loadData() {
    try {
      const response = await getProducts();
      setProducts(response);
    } catch (e) {
      notify.error('Error loading products');
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Stack spacing={4}>
      <Text
        fontWeight="semibold"
        fontSize="xl"
        color={isPlanExpired(user.planExpirationDate) && 'red.500'}
        whiteSpace="break-spaces"
        textAlign="center"
      >
        {isPlanExpired(user.planExpirationDate)
          ? 'Your plan is expired'
          : 'Your plan expires ' +
            moment(user.planExpirationDate).format('MM/DD/YYYY')}
      </Text>
      {products.map(product => (
        <BillingCard
          priceText={Number(product.price / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
          length={product?.metadata?.days}
          priceId={product.priceId}
        />
      ))}
    </Stack>
  );
}

export default Billing;
