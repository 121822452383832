import React from 'react';
import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useFormInput, useLoading, useViewController } from '../utils/hooks';
import {
  requestForgotPasswordCode,
  submitForgotPasswordRequest,
} from '../services/AuthService';
import { useNotifyContext } from '../context/NotifyContext';

function ForgotPassword({ goBackToLogin }) {
  const loader = useLoading();
  const phoneNumber = useFormInput('');
  const code = useFormInput('');
  const newPassword = useFormInput('');
  const newPasswordConfirm = useFormInput('');
  const viewController = useViewController(
    ['PHONE_ENTRY', 'CODE'],
    'PHONE_ENTRY',
  );

  const notify = useNotifyContext();

  async function onSubmit() {
    try {
      loader.start();
      const response = await submitForgotPasswordRequest(
        phoneNumber.value,
        code.value,
        newPassword.value,
      );

      console.log('RESPONSE: ', response);
      notify.success('Successfully changed password');
      loader.stop();
      goBackToLogin();
    } catch (e) {
      notify.error('Error submitting request: ' + e.message);
      loader.stop();
    }
  }

  async function onRequest() {
    try {
      loader.start();
      const requested = await requestForgotPasswordCode(phoneNumber.value);
      notify.success(requested);
      viewController.set(viewController.VIEWS.CODE);
      loader.stop();
    } catch (e) {
      notify.error('Error requesting code: ' + e.message);
      loader.stop();
    }
  }

  return (
    <React.Fragment>
      {viewController.current === viewController.VIEWS.PHONE_ENTRY && (
        <React.Fragment>
          <Text fontWeight="semibold" fontSize="lg">
            Reset your password
          </Text>
          <FormControl isRequired id="phoneNumber">
            <InputGroup>
              <InputLeftAddon children="+1" />

              <Input
                disabled={loader.isLoading()}
                {...phoneNumber}
                placeholder="phone number"
                type="tel"
              />
            </InputGroup>
          </FormControl>
          <Button
            onClick={onRequest}
            isLoading={loader.isLoading()}
            isDisabled={!phoneNumber.value || loader.isLoading()}
            colorScheme="blue"
          >
            Request
          </Button>
        </React.Fragment>
      )}
      {viewController.current === viewController.VIEWS.CODE && (
        <React.Fragment>
          <Text fontWeight="semibold" fontSize="lg">
            Reset your password
          </Text>
          <Text whiteSpace="break-spaces">
            {
              'We just sent you a 6 digit code to\nthe phone number you entered.'
            }
          </Text>
          <FormControl isRequired id="code">
            <InputGroup>
              <Input
                disabled={loader.isLoading()}
                {...code}
                placeholder="Code"
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired id="newPassword">
            <InputGroup>
              <Input
                disabled={loader.isLoading()}
                {...newPassword}
                placeholder="New Password"
                type="password"
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired id="newPasswordConfirm">
            <InputGroup>
              <Input
                disabled={loader.isLoading()}
                {...newPasswordConfirm}
                placeholder="New Password Confirmation"
                type="password"
              />
            </InputGroup>
          </FormControl>
          <Button
            isDisabled={
              !code.value ||
              !newPassword.value ||
              !newPasswordConfirm.value ||
              newPassword.value !== newPasswordConfirm.value ||
              loader.isLoading()
            }
            onClick={onSubmit}
            isLoading={loader.isLoading()}
            colorScheme="blue"
          >
            Submit Request
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ForgotPassword;
